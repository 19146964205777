header{
  background: linear-gradient(139deg, #0085FF, rgb(0 26 117));
  color: white;
  height: 350px;
  padding: 50px 20px 0px 20px;
  width: 100vw;
}

header .header__input{
  border: none;
  border-radius: 6px;
  height: 40px;
  padding: 20px 10px;
  width: 800px;
}
header .header__input:focus{
  outline: none;
}
header .header__cont-input{
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.emptyTODO {
  background-color: transparent;
  padding: 13px;
  text-align: center;
}

#btnSend {
  background-color: #ffffff;
  border: none;
  border-left: 1px solid #2094ff;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  color: #0085FF;
  font-size: 20px;
  height: 40px;
  margin-left: -8px;
  text-align: center;
  width: 80px;
}
#btnSend:hover{
  background-color: #cee8ff;
  border-left: 1px solid #cee8ff;
  transition: .5s;
}
#btnSend img{
  margin-top: 6px;
  width: 25px;
}

header .header__completed{
  margin-top: 30px;
  text-align: right;
}

.todos{
  align-items: center;
  display: flex;
  flex-direction: column;
}

.todos__love {
  position: absolute;
  right: 40px;
  top: 0;
  width: 90px;
  transform: rotate(180deg);
}

.filter {
  align-items: center;
  border-radius: 6px;
  background-color: white;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 40%;
  height: 30px;
  justify-content: space-around;
  position: fixed;
  width: 110px;
}
.filter button{
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
}
.filter__filter{
  display: flex;
  justify-content: center;
  width: 100%;
}
.filter__filter .nothing{
  background-color: white;
  border: 1px solid #0085FF;
  border-radius: 100%;
  height: 23px;
  width: 23px;
}

.border-red{
  border: 1px solid red;
}
.minValue{
  font-size: 13px;
}
.hidden{
  display: none;
}
.ModalBackground {
    background: rgba(32,35,41,.8);
    position: fixed;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

/* ------------- DELETE MODAL -------------*/


.deleteModal{
    align-items: center;
    background-color:white ;
    border-top: 5px solid #0085FF;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 250px;
    justify-content: center;
    padding: 20px 40px;
    position: absolute;
    text-align: center;
    width: 400px;
}

.deleteModal__exit {
    color: #f64040;
    cursor: pointer;
    font-size: 30px;
    margin-right: -360px;
    position: relative;
    top: -102px;
    z-index: 1;
}
.deleteModal__exit:hover{
    color: #bd1313;
}
.deleteModal__top{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 10px;
}

.deleteModal__bottom{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.deleteModal__btn{
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-weight: 800;
    height: 30px;
    width: 30%;
    letter-spacing: 1px;
}

.btnCancel{
    background-color: #f64040;
}
.btnSucces{
    background-color: #3bb54a;
}
.btnCancel:hover{
    background-color: #bd1313;
    transition: .4s;
}
.btnSucces:hover{
    background-color: #0d941d;
    transition: .4s;
}

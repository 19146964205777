.todos__contenedor{
    background-color: white;
    border-radius: 6px;
    box-shadow: 4px 3px 7px 1px #00000063;
    margin-top: -65px;
    width: 87%;
}


.todos__item{
    align-items: center;
    border-bottom: 1px solid #e5e5e5 ;
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 15px 10px;
    justify-content: space-between;
}

.todos__item-completed{
    background-color: #ededed;
}

.todos__task{
    color: #4a4a4a;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 83%;
    word-wrap: break-word;
}
.todos__task-completed{
    text-decoration: line-through;
}

.todos__check{
    border: 1px solid #0085FF;
    border-radius: 100%;
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.todos__item .todos__check-completed{
    border: none;
    content: url('https://cdn-icons-png.flaticon.com/512/845/845646.png');
}

.todos__button-delete{
    background-color: #ffffff00;
    border: none;
    color: red;
    font-size: 15px;
    margin-top: 7px;
}

.todos__button-delete:hover{
    color: rgb(180, 0, 0);
}

.hidden{
    display: none;
}

.noDelete{
    color: #4a4a4a;
    pointer-events: none;
}
